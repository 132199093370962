import styled, { css } from 'styled-components';

const LinkUnderline = (isShowing: boolean) => css`
    &:after {
        content: '';
        position: absolute;
        bottom: -6px;
        left: 0;
        right: 1.5px;
        border-bottom: 2px solid ${({ theme }) => theme.palette.White};
        opacity: ${isShowing ? 1 : 0};
        transition: opacity 50ms ease;
    }
`;

const LinkUnderlineSimple = (isShowing: boolean) => css`
    &:after {
        content: '';
        position: absolute;
        bottom: -2px;
        left: 0;
        right: 0;
        border-bottom: 2px solid ${({ theme }) => theme.palette.White};
        opacity: ${isShowing ? 1 : 0};
        transition: opacity 50ms ease;
    }
`;

export const LinkStyles = css`
    font-family: 'Grotesque Bold', Helvetica, Arial, sans-serif;
    font-size: 12px;
    color: ${({ theme }) => theme.palette.White};
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    position: relative;
    display: inline-flex;
    align-items: center;

    ${LinkUnderline(false)}

    @media (hover: hover) {
        &:hover {
            ${LinkUnderline(true)}
        }
    }
`;

export const NavLink = styled.a`
    ${LinkStyles}
    ${LinkUnderline(false)}

    &.is_active {
        color: ${({ theme }) => theme.palette.White};
        ${LinkUnderline(true)}
    }

    @media (hover: hover) {
        &:hover {
            color: ${({ theme }) => theme.palette.White};
        }
    }
`;

export const Link = styled.a<{ decoration?: string }>`
    ${LinkStyles};

    ${({ decoration }) => {
        if (decoration === 'silent') {
            return `
                @media (hover: hover) {
                    &:hover:after {
                        display: none;
                    }
                }
            `;
        }
    }}
`;

export const PseudoLink = styled.span<{ isActive?: boolean }>`
    ${LinkStyles}
    cursor: pointer;

    ${({ isActive }) => isActive && LinkUnderline(true)}
`;

export const SimpleLink = css`
    font-family: 'Grotesque Bold', Helvetica, Arial, sans-serif;
    letter-spacing: -0.2px;
    color: ${({ theme }) => theme.palette.White};
    padding-bottom: 2px;
    border-bottom: none;
    position: relative;
    ${LinkUnderlineSimple(false)}

    @media (hover: hover) {
        &:hover {
            ${LinkUnderlineSimple(true)}
        }
    }
`;

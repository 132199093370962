const paths = {
    home: () => '/',
    terms: () => 'https://legal.getplayback.com/terms',
    privacy: () => 'https://legal.getplayback.com/privacy',
    copyright: () => 'https://legal.getplayback.com/copyright',
    faq: () => 'https://about.getplayback.com/faq',
    about: () => 'https://about.getplayback.com',
    careers: () => 'https://about.getplayback.com/careers',
};

export default paths;

// https://blog.logrocket.com/dealing-with-links-in-next-js/
import React, { forwardRef } from 'react';
import Link from 'next/link';
import { NavLink } from 'components/Link';
import { useRouter } from 'next/router';
import { Path } from 'typescript/typings';

interface IProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
    // allow both static and dynamic routes
    to: Path;
    prefetch?: boolean;
    activeClassName?: string;
    routes?: RegExp;
    format?: 'default' | 'nav';
}

const WrappedLink: React.ForwardRefRenderFunction<HTMLAnchorElement, IProps> = (props, ref) => {
    const { to, prefetch, activeClassName, routes, format = 'default', className, ...rest } = props;
    const router = useRouter();
    let updatedClassName = className;

    if (activeClassName) {
        const route = typeof to === 'string' ? to : to.href;
        if (routes ? routes.test(router.asPath) : route === router.asPath) {
            updatedClassName = updatedClassName
                ? `${updatedClassName} ${activeClassName}`
                : activeClassName;
        }
    }

    // when we just have a normal url we just use it
    if (typeof to === 'string') {
        return (
            <Link href={to} prefetch={prefetch || false} passHref>
                {format === 'nav' ? (
                    <NavLink className={updatedClassName} {...rest} ref={ref} />
                ) : (
                    <a className={updatedClassName} {...rest} ref={ref} />
                )}
            </Link>
        );
    }

    // otherwise pass both "href" / "as"
    return (
        <Link href={to.href} as={to.as} prefetch={prefetch || false} passHref>
            <a className={updatedClassName} {...rest} ref={ref} />
        </Link>
    );
};

export default forwardRef(WrappedLink);

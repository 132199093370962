import { useEffect } from 'react';

const DEFAULT_TITLE = 'Watch sports with friends';

const useDocumentTitle = (title?: string): void => {
    useEffect(() => {
        document.title = title ? title.trim() : DEFAULT_TITLE;

        return () => {
            document.title = DEFAULT_TITLE;
        };
    }, [title]);
};

export default useDocumentTitle;
